import React, { useState, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import { ProductPageQuery } from '../../graphql-types';
import Layout from '../components/Layout';
import { useShopifyService } from '../shopifyService';
import { formatCurrency } from '../helpers';
import { VariantSelector, SelectedVariant, Gallery } from '../components/ProductPage';
import { ReactComponent as ArrowLeft } from '../svg/icon/icon-arrow-left.svg';
import { ReactComponent as CheveronDown } from '../svg/icon/icon-cheveron-down.svg';
import { ReactComponent as CheveronUp } from '../svg/icon/icon-cheveron-up.svg';

interface ProductPageProps {
  data: ProductPageQuery;
}

const ProductPage: React.FC<ProductPageProps> = ({ data: staticData }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { loading, addVariantToCart, products } = useShopifyService();

  const serviceProduct = products.filter(({ id }) => {
    return id === staticData.shopifyProduct.shopifyId;
  })[0];

  const [selectedVariant, setSelectedVariant] = useState<SelectedVariant>({
    id: staticData.shopifyProduct.variants[0].shopifyId,
    price: staticData.shopifyProduct.variants[0].price,
    title: staticData.shopifyProduct.variants[0].title,
    available: staticData.shopifyProduct.variants[0].availableForSale,
  });

  const handleAddToCart = useCallback(() => {
    if (!selectedVariant) {
      return;
    }
    addVariantToCart({
      quantity: '1',
      variantId: selectedVariant.id,
    });
  }, [addVariantToCart, selectedVariant]);

  const { description } = staticData.shopifyProduct;

  const shouldTrimDescription = description.length > 90;
  const trimmedDescription = description.substring(0, 90);

  return (
    <Layout>
      <div className="md:flex md:items-start">
        <div className="md:w-7/12 ">
          <Gallery images={staticData.shopifyProduct.images} />
        </div>
        <div className="section flex-1 sticky top-0 md:w-5/12">
          <Link
            to="/products"
            className="text-xs whitespace-no-wrap flex items-center section-y-xs"
          >
            <ArrowLeft className="mr-2" />
            Back to Products
          </Link>
          <header className="section-y-xs">
            <h1 className="h3 mb-4">{staticData.shopifyProduct.title}</h1>
            {!shouldTrimDescription && <p className="text-sm lg:w-2/3">{description}</p>}
            {shouldTrimDescription && (
              <>
                <button
                  type="button"
                  className="btn-link text-xs uppercase tracking-widest mb-4"
                  onClick={() => setShowFullDescription(!showFullDescription)}
                >
                  {showFullDescription ? 'Hide' : 'Show'} full description{' '}
                  {showFullDescription ? (
                    <CheveronUp className="text-lg ml-1" />
                  ) : (
                    <CheveronDown className="text-lg ml-1" />
                  )}
                </button>
                {showFullDescription ? (
                  <p className="text-sm lg:w-2/3">{description}</p>
                ) : (
                  <p className="text-sm lg:w-2/3">{`${trimmedDescription}...`}</p>
                )}
              </>
            )}
          </header>
          <section className="mb-8">
            <VariantSelector
              initialVariant={selectedVariant}
              onChange={setSelectedVariant}
              product={serviceProduct}
            />
            <p className="h3 mt-2 mb-2">
              {formatCurrency({
                price: selectedVariant.price,
              })}
            </p>
            {selectedVariant.available && (
              <button
                type="button"
                className="btn disabled:cursor-not-allowed bg-gray-700 text-transparent-white"
                disabled={loading}
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
            )}
            {!selectedVariant.available && (
              <button
                type="button"
                className="btn cursor-not-allowed bg-gray-500 text-transparent-white"
                disabled
              >
                Currently Unavailable
              </button>
            )}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ProductPage;

export const query = graphql`
  query ProductPage($handle: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      shopifyId
      title
      handle
      description
      productType
      variants {
        shopifyId
        title
        price
        availableForSale
      }
      images {
        id
        originalSrc
        localFile {
          childImageSharp {
            fluid(maxWidth: 910, maxHeight: 910) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
