import React, { useState, useCallback, ChangeEvent, useMemo } from 'react';
import { ProductWithVariants } from '../../shopifyService';

export interface SelectedVariant {
  id: string | number;
  price: string;
  title: string;
  available: boolean;
}

interface VariantSelectorProps {
  product: ProductWithVariants;
  initialVariant: SelectedVariant;
  onChange: (variant: SelectedVariant) => void;
}

const VariantSelector: React.FC<VariantSelectorProps> = ({ product, initialVariant, onChange }) => {
  const [selectedVariant, setSelectedVariant] = useState<SelectedVariant>(initialVariant);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const variant = product.variants.filter(({ id }) => id === e.target.value)[0];

      const formattedVariant = {
        id: variant.id,
        price: variant.price,
        title: variant.title,
        available: variant.available,
      };

      setSelectedVariant(formattedVariant);
      onChange(formattedVariant);
    },
    [product, onChange]
  );

  // TODO Implement improved loading state here - defaulting to static data
  const isSoleProductVariant = useMemo(() => {
    return (
      product && product.variants.length === 1 && product.variants[0].title === 'Default Title'
    );
  }, [product]);

  if (isSoleProductVariant) {
    return null;
  }

  return (
    <>
      {product && (
        <select
          className="form-select block w-full text-sm"
          value={selectedVariant.id}
          onChange={handleOnChange}
        >
          {product.variants.map(({ id, title }) => {
            return (
              <option key={id} value={id}>
                {title}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
};

export default VariantSelector;
