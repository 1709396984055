import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';

import PreviewCompatibleImage from '../PreviewCompatibleImage';

import { ShopifyProductImages } from '../../../graphql-types';

export interface GalleryProps {
  images: ShopifyProductImages[];
}

export const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const [imageIndex, setImageIndex] = useState('0');

  const transition = useTransition(imageIndex, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const Image = animated(PreviewCompatibleImage);

  return (
    <>
      <div className="relative">
        {transition.map(
          ({ item, props, key }) =>
            item === imageIndex && (
              <Image
                key={key}
                style={props}
                className="max-h-screen absolute inset-0 opacity-0"
                imageInfo={images[imageIndex].localFile}
              />
            )
        )}
      </div>
      <div className="grid grid-cols-3 md:grid-cols-2 gap-4 p-4">
        {images.map(({ id, localFile }, i) => {
          const handleOnClick = () => {
            setImageIndex(i.toString());
          };
          return (
            <button key={id} type="button" onClick={handleOnClick}>
              <PreviewCompatibleImage imageInfo={localFile} />
            </button>
          );
        })}
      </div>
    </>
  );
};
